import React, { useState } from "react";
import { Router } from "@reach/router";
import {
  PrivacySection,
  QuoteLayout,
  Thankyou,
} from "../../components/utility/quote";
import { Product_Quote_Routes } from "../../data/Routes";
import MarketingContext from "../../contexts/marketingContext";
import firebase from "gatsby-plugin-firebase";
import Question1 from "./Question1";
import Question2 from "./Question2";
import Question3 from "./Question3";
import Form from "./Form";
import { ServicesMenuSection } from "../../components/containers/ServicesMenuSection";
import { Footer } from "../../components/utility";
import GtagCode from "../../data/GtagCode";
const Quote = () => {
  const [que1, setQue1] = useState(null);
  const [que2, setQue2] = useState(null);
  const [que3, setQue3] = useState(null);
  const [formValues, setFormValues] = useState(null);

  const addLeadToBackend = async (values) => {
    await fetch("/.netlify/functions/sendgrid", {
      method: "POST",
      body: JSON.stringify({
        email: "hitesh.patel@icthub.com.au",
        subject: "New lead recived!!",
        name: values.fullname,
      }),
    });
    var timestamp = firebase.firestore.FieldValue.serverTimestamp();
    await firebase.firestore().collection("Digital_Web_Marketing").add({
      customerDetails: values,
      "What is your objective?": que1,
      "What services do you need?": que2,
      "What is your estimate monthly budget (including as spend)": que3,
      Lead_Generated: timestamp,
    });
    try {
      const response = fetch("/.netlify/functions/sendgrid", {
        method: "POST",
        body: JSON.stringify({
          email: values.email,
          subject: "Digital Comparison",
          name: values.fullname,
        }),
      });
      if (!response.ok) {
        //not 200 response
        return;
      }
    } catch (e) {
      //error
      console.log(e);
    }
  };

  return (
    <QuoteLayout>
      <GtagCode />
      <MarketingContext.Provider
        value={{
          que1,
          setQue1,
          que2,
          setQue2,
          que3,
          setQue3,
          formValues,
          setFormValues,
          addLeadToBackend,
        }}
      >
        <Router>
          <section path={Product_Quote_Routes.DigitalWebMarketing}>
            <Question1 title="Digital Web Marketing" path="/" />
            <Question2 title="Digital Web Marketing" path="/2" />
            <Question3 title="Digital Web Marketing" path="/3" />
            <Form title="Digital Web Marketing" path="/4" />
            <Thankyou title="Digital Web Marketing" path="/thankyou" />
          </section>
        </Router>
      </MarketingContext.Provider>
      <PrivacySection /> <ServicesMenuSection />
      <Footer />
    </QuoteLayout>
  );
};

export default Quote;
