import React, { useContext } from "react";
import { QuestionSection, RadioRange } from "../../components/utility/quote";
import { navigate } from "gatsby-link";
import RadioCircle from "../../svgs/radioCircle";
import MarketingContext from "../../contexts/marketingContext";

const Next = "/digital-web-marketing/Questions/4";
const Points = [
  {
    question: "What is your estimate monthly budget (including as spend)?",
    progress: "75%",
    options: [
      {
        answer: "$1000-$2000",
        to: Next,
        icon: <RadioCircle />,
      },
      { answer: "$2000-$5000", to: Next, icon: <RadioCircle /> },
      { answer: "$5000-$10000", to: Next, icon: <RadioCircle /> },
      { answer: "$10000+", to: Next, icon: <RadioCircle /> },
      { answer: "No Budget allocated yet", to: Next, icon: <RadioCircle /> },
    ],
  },
];

export default function Question3({ title }) {
  const { que3, setQue3 } = useContext(MarketingContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <RadioRange
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que3 === items.answer}
              onChange={(e) => {
                setQue3(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
